body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
pre {outline: 1px solid #ccc; padding: 5px; margin: 5px; }
.string { color: blue; }
.number { color: darkorange; }
.boolean { color: skyblue; }
.null { color: magenta; }
.key { color: green; }

div.tox-toolbar__group[title="Szablon"] > button > span.tox-tbtn__select-label {
	font-size: 20px;
	padding-bottom: 4px;
} 